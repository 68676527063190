import React, { FC } from "react";
// import styles from "./AboutAppArticle.module.scss";

import AppArticlCardInCard, {
  AppArticlCardInCardProps,
} from "components/AppArticle/CardInCard/AppArticlCardInCard";

interface AboutAppArticleProps {}

const AboutAppArticle: FC<AboutAppArticleProps> = () => {
  const props: AppArticlCardInCardProps = {
    title: {
      value: "О компании",
    },
    paragraphs: [
      {
        value:
          "Настоящий сайт является официальным сайтом Общества с ограниченной ответственностью «Центр компьютерных технологий».",
      },
      {
        value:
          "Наша компания была основана в 1999 г. как ЗАО «Центр компьютерных технологий» (ИНН 720500792518). В 2014 г. ЗАО «Центр компьютерных технологий» было реорганизовано в форме преобразования в ООО «Центр компьютерных технологий» (ИНН 7205026475).",
      },
      {
        value:
          "Наша компания осуществляет деятельность в области информационных технологий, включена в Реестр аккредитованных организаций, осуществляющих деятельность в области информационных технологий (номер в реестре 645, дата решения об аккредитации 03.09.2014).",
      },
      {
        value:
          "Основной вид деятельности компании: адаптация, модификация и сопровождение Справочных Правовых Систем КонсультантПлюс (ОКВЭД 62.01 – «Разработка компьютерного программного обеспечения», 63.11.1 – «Деятельность по созданию и использованию баз данных и информационных ресурсов»).",
      },
      {
        value:
          "СПС КонсультантПлюс зарегистрирована в едином реестре российских программ для электронных вычислительных машин и баз данных. Запись в реестре № 212 от 18.03.2016 произведена на основании Приказа Министерства цифрового развития, связи и массовых коммуникаций Российской Федерации от 18.03.2016 № 112.",
      },
      {
        value:
          "Компания осуществляет также и другие виды деятельности в области информационных технологий.",
      },
    ],
  };
  return (
    // <div className={styles.AboutAppArticle}>
    <AppArticlCardInCard {...props} />
    // </div>
  );
};

export default AboutAppArticle;
