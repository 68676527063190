import React, { FC } from "react";
import styles from "./MainAppCarousel.module.scss";

import AppCarousel from "components/AppCarousel/AppCarousel";

interface MainAppCarouselProps {}

const MainAppCarousel: FC<MainAppCarouselProps> = () => (
  <div className={styles.MainAppCarousel}>
    <AppCarousel
      items={[
        {
          title: "Система КонсультантПлюс",
          description:
            "Это эффективный инструмент и надежный помощник для специалиста, самая полная база правовой информации, дружественный интерфейс и современные программные технологии",
          imgSrc: "img/main/carousel-1.jpg",
        },
        {
          title: "Состав системы КонсультантПлюс",
          description:
            "Нормативные документы, финансовые и кадровые консультации, комментарии законодательства, проекты правовых актов, судебная практика, технические нормы и правила, международные правовые акты, готовые решения и аналитические материалы.",
          imgSrc: "img/main/carousel-2.jpg",
        },
        {
          title: "Сопровождение системы",
          description:
            "Обеспечение функционирования, обновление и восстановление работоспособности системы, сервисные услуги, определенные Стандартом качества КонсультантПлюс.",
          imgSrc: "img/main/carousel-3.jpg",
        },
      ]}
    />
  </div>
);

export default MainAppCarousel;
