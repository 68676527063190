import React, { FC } from "react";
import styles from "./Contacts.module.scss";

import CardInCard from "components/CardInCard/CardInCard";

export interface ContactsProps {}

const Contacts: FC<ContactsProps> = (props) => {
  const title = <h2 className="my-4">Контакты</h2>;
  const content = (
    <div className="row items-center">
      <div className="col-12 col-md-6  my-3 d-flex justify-content-center align-items-center">
        <div>
          <div className="fw-bold">
            Общество с ограниченной ответственностью <br />
            «Центр компьютерных технологий»
          </div>
          <div>ИНН 7205026475</div>
          <div>ОГРН 1147232040273</div>
          <div className="my-3"></div>
          <div className="fw-bold">Адрес</div>
          <a href="https://yandex.ru/maps/-/CCUb7OaQKA" target="__blank">
            <div>
              627753, Тюменская область, <br />
              г. Ишим, ул. 30 лет ВЛКСМ, д. 28/2
            </div>
          </a>
          <div className="my-3"></div>
          <div className="fw-bold">Телефон</div>
          <div>
            <a href="tel:+73455174470">+7 (34551) 7‒44‒70</a>
          </div>
          <div className="my-3"></div>
          <div className="fw-bold">Электронная почта</div>
          <div>
            <a href="mailto:ya.ctc@yandex.ru">ya.ctc@yandex.ru</a>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 d-flex">
        {/* https://yandex.ru/map-constructor */}
        <iframe
          className={styles.ContactsMap}
          title="yamap"
          src="https://yandex.ru/map-widget/v1/?um=constructor%3A3505b87fb593ee5ca15787c50cb49cd3b3e3a8c6aaa78052d96d9ac18c285424&amp;source=constructor"
          width="100%"
          height="368"
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
  const cardInCardProps = { title, content };
  return (
    <div className={styles.Contacts}>
      <CardInCard {...cardInCardProps} />
    </div>
  );
};

export default Contacts;
